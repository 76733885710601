//Actions
export const INC_FRAME_ACTUAL = 'INC_FRAME_ACTUAL'
export const SET_BEGIN_FRAME = 'SET_BEGIN_FRAME'

export const SET_DIMENSIONS = 'SET_DIMENSIONS'

const frames = []
for (let i = 0; i <= 123; i++) {
  frames.push({
    id: `${i}`,
    src: `frames_resize/Comp 1_00${i < 10 ? '00' : i < 100 ? '0' : ''}${i}.jpg`
  })
}

const initialState = {
  frames,
  frameActual: 0,
  beginFrame: 0,
  widthImage: 1500,
  heightImage: 1125,
  width: 0,
  height: 0
}

export const incFrameActual = inc => ({
  type: INC_FRAME_ACTUAL,
  inc
})

export const setBeginFrame = () => ({
  type: SET_BEGIN_FRAME
})

export const setDimensions = ({ width, height }) => ({
  type: SET_DIMENSIONS,
  width,
  height
})

export default (state = initialState, action) => {
  switch (action.type) {
    case INC_FRAME_ACTUAL:
      const newIndex = state.beginFrame + action.inc

      return {
        ...state,
        frameActual:
          newIndex < 0
            ? 0
            : newIndex > state.frames.length - 1
            ? state.frames.length - 1
            : newIndex
      }
    case SET_BEGIN_FRAME:
      return {
        ...state,
        beginFrame: state.frameActual
      }
    case SET_DIMENSIONS:
      return {
        ...state,
        width: action.width,
        height: action.height
      }
    default:
      return state
  }
}
