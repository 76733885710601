import React, { useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { incFrameActual, setBeginFrame } from '../modules/reducer'
import { getLoaded } from '../modules/reducerLoadImages'

import { useGesture } from 'react-use-gesture'

import useDimensions from './useDimensions'

import ImageFrame from './ImageFrame'
import ImageLoader from './ImageLoader'

const ImageFluid = () => {
  const dispatch = useDispatch()

  const numFrames = useSelector(state => state.reducer.frames.length)

  const loaded = useSelector(state => getLoaded(state))
  //const loaded = false

  const firstGesture = useRef(false)

  const bind = useGesture(
    {
      onDragStart: () => {
        if (loaded) {
          dispatch(setBeginFrame())
        } else {
          firstGesture.current = true
        }
      },
      onDrag: ({ event, delta, offset: [x], movement }) => {
        dispatch(incFrameActual(Math.round(movement[0] / 5)))
      }
    },
    { event: { passive: false } }
  )

  const [ref] = useDimensions({ liveMeasure: false })

  const frames = []

  for (let i = 0; i < numFrames; i++) {
    frames.push(<ImageFrame key={i} index={i} />)
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100%'
      }}
    >
      <div>
        <div style={{ margin: 10 }}>
          Play with light with our Fluid Image Tool!
        </div>
        <div style={{ margin: 10 }}>
          <img src="touch_controls.png" alt="" />
        </div>
      </div>
      <div
        ref={ref}
        {...bind()}
        style={{
          position: 'relative',
          height: '90%',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}
      >
        {loaded ? (
          frames
        ) : (
          <>
            {firstGesture.current ? null : frames[0]}
            <ImageLoader />
          </>
        )}
      </div>
    </div>
  )
}

export default ImageFluid
