import React from 'react'
import ImageFluid from './components/ImageFluid'
import './App.css'

import { ThemeProvider } from 'react-jss'
import Theme from './Theme'

function App() {
  return (
    <ThemeProvider theme={Theme}>
      <div className="App" style={{ height: '100vh' }}>
        <ImageFluid />
      </div>
    </ThemeProvider>
  )
}

export default App
