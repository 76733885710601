const morado = '#932032'
const grisOscuro = '#434343'
const naranja = '#ED720D'

export const theme = {
  textColor: grisOscuro,
  colorPrincipal: naranja,
  backgroundImages: '#e0e0e0',
  backgroundFrame: '#eeeeee',

  backgroundHeader: 'white',
  backgroundFooter: grisOscuro,
  iconnHeaderColor: morado,
  menuIdiomasColor: morado,
  shadowHeader: true,

  mediaScroll: '823px',
  mediaMovil: '414px'
}

export default theme
