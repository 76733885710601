import React from 'react'

import { createUseStyles, useTheme } from 'react-jss'

import { useSelector } from 'react-redux'
import { getPorcentajeLoaded } from '../modules/reducerLoadImages'

//https://codepen.io/JMChristensen/pen/Ablch
//https://stackoverflow.com/questions/14218607/javascript-loading-progress-of-an-image

const useStyles = createUseStyles({
  svgCircle: {
    transition: 'stroke-dashoffset 0.01s linear',
    stroke: '#666',
    strokeWidth: '1em'
  },
  svgBar: {
    transition: 'stroke-dashoffset 0.01s linear',
    strokeWidth: '1em',
    stroke: ({ theme }) => theme.colorPrincipal
  },
  cont: {
    zIndex: 30,
    display: 'block',
    height: '200px',
    width: '200px',
    margin: '2em auto',
    boxShadow: '0 0 1em white',
    borderRadius: '100%',
    position: 'relative',
    '&:after': {
      color: 'white',
      position: 'absolute',
      display: 'block',
      height: '160px',
      width: '160px',
      left: '50%',
      top: '50%',
      boxShadow: 'inset 0 0 1em black',
      content: "attr(data-pct) '%'",
      marginTop: '-80px',
      marginLeft: '-80px',
      borderRadius: '100%',
      lineHeight: '160px',
      fontSize: '2em',
      textAlign: 'center'
    }
  }
})

const IconLoadingNumber = () => {
  const porcentaje = useSelector(getPorcentajeLoaded)

  const r = 90
  const c = Math.PI * (r * 2)

  let pct
  if (porcentaje < 0) {
    pct = 0
  }
  if (porcentaje > 100) {
    pct = 100
  }

  pct = ((100 - porcentaje) / 100) * c

  const theme = useTheme()
  const classes = useStyles({ theme })

  return (
    <div id="cont" className={classes.cont} data-pct={porcentaje}>
      <svg
        id="svg"
        width="200"
        height="200"
        viewport="0 0 100 100"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          className={classes.svgCircle}
          r="90"
          cx="100"
          cy="100"
          fill="transparent"
          strokeDasharray="565.48"
          strokeDashoffset="0"
        />
        <circle
          className={classes.svgBar}
          id="bar"
          r="90"
          cx="100"
          cy="100"
          fill="transparent"
          strokeDasharray="565.48"
          strokeDashoffset={pct}
        />
      </svg>
    </div>
  )
}

export default IconLoadingNumber
