import { useState, useCallback, useLayoutEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setDimensions } from '../modules/reducer'

function getDimensionObject(node) {
  const rect = node.getBoundingClientRect()

  return {
    width: rect.width,
    height: rect.height,
    top: 'x' in rect ? rect.x : rect.top,
    left: 'y' in rect ? rect.y : rect.left,
    x: 'x' in rect ? rect.x : rect.left,
    y: 'y' in rect ? rect.y : rect.top,
    right: rect.right,
    bottom: rect.bottom
  }
}

function useDimensions({ liveMeasure = true }) {
  const dispatch = useDispatch()
  const [node, setNode] = useState(null)

  const ref = useCallback(node => {
    setNode(node)
  }, [])

  useLayoutEffect(() => {
    if (node) {
      const measure = () =>
        window.requestAnimationFrame(() =>
          dispatch(setDimensions(getDimensionObject(node)))
        )
      measure()

      if (liveMeasure) {
        window.addEventListener('resize', measure)
        window.addEventListener('scroll', measure)

        return () => {
          window.removeEventListener('resize', measure)
          window.removeEventListener('scroll', measure)
        }
      }
    }
  }, [node, liveMeasure, dispatch])

  return [ref, node]
}

export default useDimensions
