import React from 'react'
import { useSelector } from 'react-redux'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  imageFrame: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '90%',
    userSelect: 'none',
    userDrag: 'none',
    pointerEvents: 'none'
  }
})

const ImageFrame = ({ index }) => {
  const visible = useSelector(state => index === state.reducer.frameActual)
  const frame = useSelector(state => state.reducer.frames[index])

  const sizeContainerWidth = useSelector(state => state.reducer.width)
  const sizeContainerHeight = useSelector(state => state.reducer.height)
  const sizeImageWidthO = useSelector(state => state.reducer.widthImage)
  const sizeImageHeightO = useSelector(state => state.reducer.heightImage)

  const ratioImage = sizeImageWidthO / sizeImageHeightO
  const sizeImage = { width: 0, height: 0 }
  const position = { left: 0, top: 0 }
  const ratioContainer = sizeContainerWidth / sizeContainerHeight

  if (ratioContainer < ratioImage) {
    sizeImage.width = sizeContainerWidth
    sizeImage.height = sizeContainerWidth / ratioImage
    position.left = 0
    position.top = (sizeContainerHeight - sizeImage.height) / 2
  } else {
    sizeImage.width = sizeContainerHeight * ratioImage
    sizeImage.height = sizeContainerHeight
    position.left = (sizeContainerWidth - sizeImage.width) / 2
    position.top = 0
  }

  const isFirefox = typeof InstallTrigger !== 'undefined'

  const classes = useStyles()

  return (
    <img
      className={classes.imageFrame}
      draggable="false"
      style={{
        display: isFirefox ? 'inline-block' : visible ? 'inline-block' : 'none',
        zIndex: !isFirefox ? 10 : visible ? 20 : 10,
        ...position,
        ...sizeImage
      }}
      src={frame.src}
      alt=""
    />
  )
}

export default ImageFrame
